/*******************************
         Site Overrides
*******************************/

/*--------------------
       Disabled
---------------------*/

.ui.disabled.input, .ui.input:not(.disabled) input[disabled]{
    opacity: 1 !important;
}

.ui.form .disabled.field input{
    background: @offWhite;
}

.ui.form .disabled.field .selection.dropdown{
    background: @offWhite;
}

.ui.form .field > label {
  letter-spacing: 0.01em;
}
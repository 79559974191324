/*******************************
         Site Overrides
*******************************/

/* jika variable @borderRadius diubah, jangan lupa diubah juga di sini */
/* Regular Label on Left */

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child {
  border-bottom-left-radius: .5em;
  border-top-left-radius: .5em;
}

.ui.labeled.input > .label:not(.corner) {
  border-top-right-radius: .5em;
  border-bottom-right-radius: .5em;
}
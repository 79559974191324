/*******************************
         Site Overrides
*******************************/

a {
  text-decoration: underline;
}
a:hover {
  text-decoration: underline;
}

p {
  line-height: 1.5em;
}
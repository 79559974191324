/*******************************
         Site Overrides
*******************************/


.ui.blue.labels .label,
.ui.blue.label {
  background-color: darken(@blueBackground, 5)  !important;
}

.ui.red.labels .label,
.ui.red.label {
  background-color: darken(@redBackground, 5)  !important;
}

.ui.orange.labels .label,
.ui.orange.label {
  background-color: darken(@orangeBackground, 5)  !important;
}

.ui.yellow.labels .label,
.ui.yellow.label {
  background-color: darken(@yellowBackground, 5)  !important;
}

.ui.olive.labels .label,
.ui.olive.label {
  background-color: darken(@oliveBackground, 5)  !important;
}

.ui.teal.labels .label,
.ui.teal.label {
  background-color: darken(@tealBackground, 5)  !important;
}

.ui.green.labels .label,
.ui.green.label {
  background-color: darken(@greenBackground,5)  !important;
}

.ui.violet.labels .label,
.ui.violet.label {
  background-color: darken(@violetBackground, 5)  !important;
}

.ui.purple.labels .label,
.ui.purple.label {
  background-color: darken(@purpleBackground, 5)  !important;
}

.ui.pink.labels .label,
.ui.pink.label {
  background-color: darken(@pinkBackground, 5)  !important;
}

.ui.brown.labels .label,
.ui.brown.label {
  background-color: darken(@brownBackground, 5)  !important;
}

.ui.grey.labels .label,
.ui.grey.label {
  background-color: lighten(@grey, 40)  !important;
}

.ui.black.labels .label,
.ui.black.label {
  background-color: @grey  !important;
}

.ui.label {
    text-decoration: none;
}
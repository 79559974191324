/*******************************
         Site Overrides
*******************************/

.ui.button{
  letter-spacing: .02em;
  border: solid 1px #00000012;
}


.ui.basic.button {
  border: none;
}
